import "./App.css";

function App() {
  window.location.replace(
    "https://new.zedoingresso.com.br/triadeentreterimento"
  );
  return <div className="App"></div>;
}

export default App;
